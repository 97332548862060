@import "../../../assets/styles/vars.scss";

.a-table {
  margin-top: 30px;
  display: flex;
  padding: 30px 20px;
  border-radius: 10px;
  border: 1px solid $primary100;
  overflow: auto;
  max-width: 100%;
  // width: max-content;
}

.a-table__col {
  &:first-of-type {
    margin-right: 30px;
  }
  &:not(:last-of-type) {
    margin-right: 46px;
  }
}

.a-table__header {
  color: $gray;
  margin-bottom: 30px;
  transition: all 0.3s;
  padding: 0 12px;

  cursor: col-resize;
  &:hover {
    color: $primary700;
  }
}

.a-table__item {
  display: flex;
  align-items: center;
  height: 30px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 8px 12px;
  transition: all 0.3s;

  &:hover {
    border-color: $primary700;
  }

  &_label {
    border: none;
    &:hover {
      border: none;
    }
  }
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

a.a-table__item {
  color: $primary700;
  transition: all 0.3s;
  &:hover {
    color: $primary500;
    border-color: transparent;
  }
  &:active {
    color: $primary900;
    border-color: transparent;
  }
}

.a-table__col {
  flex-shrink: 0;
}

.a-table__col_centered .a-table__item {
  text-align: center;
  justify-content: center;
}

.a-table__col_centered .a-table__header {
  text-align: center;
  justify-content: center;
}

.a-table__col_short {
  white-space: nowrap;
  overflow: hidden;

  .a-table__item:hover {
    border-color: transparent;
  }
}

.a-table__col_short .a-table__item,
.a-table__col_short .a-table__header {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    background-image: linear-gradient(to right, transparent, $white);
  }
}
