@import "../../assets/styles/vars.scss";
@import "../../assets/styles/mixins.scss";

.ReactModal__Body--open {
  overflow: hidden;
}

.modal-wrapper {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  position: relative;
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: fit-content;
  margin: auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white-opacity;
  backdrop-filter: blur(5px);
  z-index: 10;
  overflow: auto;
}

.modal {
  position: relative;
  width: 562px;
  max-width: 100%;
  padding: 60px;
  border-radius: 20px;
  border: 1px solid $primary100;
  background-color: $ghostWhite;

  &:focus-visible {
    outline: none;
  }
}

.modal_lg {
  width: 986px;
}

.modal__title {
  display: block;
  margin-bottom: 30px;

  @include base-24;
  @include medium;
}

.modal__close-btn {
  position: absolute;
  top: 30px;
  right: 44px;
}
.modal__back-btn {
  position: absolute;
  top: 30px;
  left: 57px;
}

.modal .sell-info {
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.modal__info {
  margin-top: 30px;
  margin-bottom: 30px;
}

.modal__btn {
  margin-top: 30px;
}

.modal__qr {
  display: flex;
  justify-content: center;
}
