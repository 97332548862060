@import "../../assets/styles/vars.scss";
@import "../../assets/styles/mixins.scss";
.jk__grid {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 68px;

  @media (max-width: $mobile) {
    grid-template-columns: 1fr;
  }
}

.jk__info-grid {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 60px;
}

.jk__info-tokens {
  display: grid;
  gap: 30px;
}

.jk__banner {
  margin-top: 60px;
}

.jk__title {
  display: flex;
  align-items: center;
  gap: 16px;
}

.jk__sub {
  color: $gray;
  @include body-small;
}
