@import "../../assets/styles/vars.scss";
@import "../../assets/styles/mixins.scss";

.ois-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  color: $secondary500;
  background-color: rgba($tertiaryError, 0.1);
}
