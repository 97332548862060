@import '../../assets/styles/vars.scss';

.home__grid {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 22px;

  @media (max-width: $mobile) {
    grid-template-columns: 1fr;
  }
}