@import "../../../assets/styles/vars.scss";
@import "../../../assets/styles/mixins.scss";

.comparison-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  background-color: $ghostWhite;
  border: 1px solid $primary100;
  border-radius: 20px;
}

.comparison-block__content {
  display: grid;
  gap: 20px;
}

.comparison-block__title {
  display: flex;
  align-items: center;
  gap: 6px;
  line-height: 1;
  margin-bottom: 10px;
  @include h8;

  & + & {
    margin-top: 12px;
  }
}

.comparison-block__price {
  @include h7;
  color: $primary700;
}

.comparison-block__text {
  margin-top: 6px;
  @include base-14;
  @include text-gray;
}

.comparison-block__info {
  display: flex;
}

.comparison__img {
  color: $default;
}