@import "../../../assets/styles/vars.scss";
@import "../../../assets/styles/mixins.scss";

.user-menu {
  display: flex;

  @media (max-width: $mobile) {
    display: none;
  }
}

.user-menu__buttons {
  display: flex;
  gap: 24px;
}

.user-menu__interface {
  display: flex;
  align-items: center;
}

.user-menu__notification,
.user-menu__profile {
  display: flex;
  justify-content: center;
  align-items: center;

  color: $primary700;

  width: 40px;
  height: 40px;
  border: 1px solid $primary100;
  border-radius: 50%;

  transition: all 0.3s;
  &:hover {
    background-color: $white;
  }
}

.user-menu__dropdown button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary700;
  width: 40px;
  height: 40px;
}

.user-menu__notification {
  position: relative;
  margin-right: 5px;
}

.user-menu__notification-counter {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(30%, -30%);

  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  padding: 0 4px;
  background-color: $primary700;
  border-radius: 100px;
  color: $white;

  @include base-11;
  @include bold;
}

.user-menu__profile {
  margin-right: 5px;
}
