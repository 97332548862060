@import "../../../../assets/styles/vars.scss";

.notifications__group {
  margin-bottom: 100px;
  &:first-of-type {
    margin-top: 30px;
  }
}

.notifications__block {
  padding: 30px;
  border-radius: 20px;
  border: 1px solid $primary100;
  width: 660px;
  max-width: 100%;
}

.notifications__block_master {
  margin-bottom: 30px;
  width: 376px;
}

.notifications__row {
  display: flex;
  justify-content: space-between;
  
  & + & {
    margin-top: 30px;
  }
}
