@import "../../../assets/styles/vars.scss";
@import "../../../assets/styles/mixins.scss";

.collapse-toggler {
  display: inline-flex;
  align-items: center;
  gap: 8px;

  @include h8;
  @include text-blue;

  & > .collapse-icon {
    margin-top: 2px;
  }

  &_opened > .collapse-icon {
    transform: rotate(180deg);
  }
}
