@import "../../../assets/styles/vars.scss";
@import "../../../assets/styles/mixins.scss";

.select {
  width: 100%;
  background-color: $primary100;
  border-radius: 4px;
  border: none;

  @include base-14;

  .Dropdown-control {
    cursor: pointer;
    padding: 14px 30px;
    color: $gray;
    font-weight: 500;
    border: none;
    background-color: $primary100;
  }

  .Dropdown-disabled {
    opacity: 0.6;
    color: $gray;
    cursor: default;
  }

  .Dropdown-menu {
    border: 1px solid $primary100;
    white-space: nowrap;
  }

  .Dropdown-option {
    padding: 10px 30px;
    color: $gray;
    font-weight: 500;
    &:hover,
    &.is-selected {
      color: $black;
      background-color: $primary100;
    }
  }
}

.Dropdown-arrow.select__arrow {
  width: 7px;
  height: 4px;
  border: none;
  background-image: url("../../../assets/img/icons/arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
}

.select.is-open .Dropdown-arrow.select__arrow {
  transform: translateY(-50%) rotate(180deg);
}

.select_white {
  font-size: 20px;

  .Dropdown-control {
    background-color: $white;
    color: $black;
    font-weight: 400;
  }
}
