@import "../../../assets/styles/vars.scss";

.logo {
  display: flex;
  color: $primary700;

  @media (max-width: $mobile) {
    width: 60px;
    height: 17px;
    svg {
      height: 100%;
      width: 100%;
    }
  }
}
