@import "../../../../assets/styles/vars.scss";
@import "../../../../assets/styles/mixins.scss";

.mobile-menu {
  position: fixed;
  top: 40px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;

  background-color: $white;
  border: 1px solid $primary100;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.04);
  border-radius: 20px 20px 0px 0px;
  padding: 16px 30px;

  transform: translateY(9999px);
  transition: transform 0.6s;

  &.shown {
    transform: translateY(0);
    transition: transform 0.4s;
  }

  &::before {
    content: "";
    width: 20px;
    height: 2px;
    background-color: $primary100;
    position: absolute;
    top: 6px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 4px;
  }
}

.mobile-menu__close-btn {
  position: absolute;
  top: 12px;
  right: 12px;
}
