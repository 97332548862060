@import "../../../../assets/styles/vars.scss";
@import "../../../../assets/styles/mixins.scss";

.topbar {
  padding: 10px 0;

  @include base-14;
}

.topbar__block {
  display: flex;
  justify-content: space-between;
}

.nav {
  padding: 10px 0;


}

.nav ul {
  display: flex;
  align-items: center;
  gap: 40px;

  @media (max-width: $mobile) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}

.topbar__select-city {
  position: relative;
  display: flex;
  align-items: center;
}

.topbar__select-icon {
  color: $primary700;
  position: absolute;
  left: 0;
}

.select.topbar__select {
  background: none;

  .Dropdown-menu {
    width: fit-content;
    right: 0;
  }
  .Dropdown-control {
    background: none;
    padding-left: 20px;
  }

  .Dropdown-control:hover {
    box-shadow: none;
  }

  .Dropdown-option {
    padding-left: 20px;
  }
}

.burger {
  width: 36px;
  height: 36px;
}