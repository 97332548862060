$primary1000: #25769C;
$primary900: #2A82AC;
$primary800: #2E8DBC;
$primary700: #3399CC;
$primary600: #52A8D5;
$primary500: #70B7DD;
$primary400: #8FC7E6;
$primary300: #AED6EE;
$primary200: #CCE5F7;
$primary100: #EBF4FF;

$default: $primary700;
$hover: $primary500;
$pressed: $primary900;
$disabled: $primary100;

$secondary1000: #000000;
$secondary900: #1D1D1D;
$secondary800: #3A3A3A;
$secondary700: #565656;
$secondary600: #737373;
$secondary500: #909090;
$secondary400: #ADADAD;
$secondary300: #CACACA;
$secondary200: #E7E7E7;
$secondary100: #F5F5F5;

$default2: $secondary100;
$hover2: $secondary200;
$pressed2: $secondary300;
$disabled2: $secondary500;

$tertiaryError: #FF6E8C;
$tertiaryOkey: #00D1B4;
$tertiaryAttention: #FF9900;

$white: #FFFFFF;
$ghostWhite: #F9FBFF;
$gray: #909090;
$black: #1D1D1D;
$white-opacity: rgba(255, 255, 255, 0.5);

$mobile: 767px;