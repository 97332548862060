@import '../../../assets/styles/vars.scss';

.container {
  width: 1362px;
  padding: 0 15px;
  max-width: 100%;
  margin: 0 auto;

  @media (max-width: $mobile) {
    width: 100%;
    padding: 0 16px;
  }

  &_reading-page {
    max-width: 640px;
  }
}