@import "../../../assets/styles/vars.scss";
@import "../../../assets/styles/mixins.scss";

.popup-container {
  display: flex;
  position: relative;
  button {
    display: flex;
  }
}

.popup {
  display: flex;
  align-items: flex-start;
  width: fit-content;
  min-width: 240px;
  padding: 16px 20px;
  padding-right: 40px;
  background-color: $secondary100;
  border-radius: 20px;
  position: absolute;
  bottom: 100%;
  line-height: 1.3;
}

.popup__info-icon {
  margin-right: 10px;
}

.popup__text {
  @include base-12;
  @include text-gray;
}

.close-btn.popup__close-btn {
  width: 14px;
  height: 14px;
  color: $secondary600;
  position: absolute;
  top: 10px;
  right: 10px;

  svg {
    width: 100%;
    height: 100%;
  }
  &:hover {
    color: $secondary600;
  }
  &:active {
    color: $secondary600;
  }
}
