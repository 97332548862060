@import "../../../assets/styles/vars.scss";
@import "../../../assets/styles/mixins.scss";

.code-field__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 98px);
  gap: 12px;
}

.code-field__label {
  display: block;
  margin-bottom: 12px;
  @include text-gray;
  @include base-12;
}

.code-field .input_lg {
  text-align: center;
}