@import '../../../../assets/styles/vars.scss';
@import '../../../../assets/styles/mixins.scss';

.bottombar {
  padding: 24px 0;
  background-color: $ghostWhite;
  @include base-14;

  @media (max-width: $mobile) {
    padding: 12px 0;
    @include base-11;
    @include medium;
  }
}

.bottombar__block {
  display: flex;
  align-items: center;
}

.bottombar__nav {
  display: flex;
  gap: 40px;
  padding: 0 40px;
  margin-left: 102px;
  margin-right: auto;

  @media (max-width: $mobile) {
    margin-left: 16px;
    padding: 0;
    gap: 16px;
  }
}