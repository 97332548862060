.pool-label {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 4px;
  gap: 4px;
  background-color: rgba(255, 255, 255, 0.7);
  font-size: 11px;
  svg {
    margin-top: -1px;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }
}
