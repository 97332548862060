@import "../../../assets/styles/vars.scss";
@import "../../../assets/styles/mixins.scss";

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $ghostWhite;
  border-radius: 20px;
  border: 1px solid $primary100;
  overflow: hidden;
}

.card__pool-label {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 4;
}

.card__body {
  display: flex;
  flex-direction: column;
  padding: 26px 20px;

  @media (max-width: $mobile) {
    padding: 10px 20px;
  }
}

.sell-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;

  &_single {
    grid-template-columns: 1fr;
  }
}

.sell-info__key {
  display: block;
  margin-bottom: 4px;
  @include base-14;
  @include medium;
}

.sell-info__value {
  @include base-12;
  @include text-gray;
}

.sell-info__value + .sell-info__key {
  margin-top: 10px;
}

.card__labels-list {
  position: absolute;
  z-index: 4;
  top: 20px;
  left: 20px;
  width: 282px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 6px;

  &:nth-child(even) {
    justify-content: flex-end;
  }

  @media (max-width: $mobile) {
    width: 80%;
  }
}

.card__labels-wrapper {
  position: absolute;
  z-index: 4;
  top: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  justify-content: space-between;

  .card__labels-list {
    position: static;
  }

  @media (max-width: $mobile) {
    flex-direction: column;
    gap: 6px;
  }
}

.sell-info__title {
  display: block;
  margin-bottom: 12px;
  @include h8;
  @include text-blue;
}

.sell-info__value + .sell-info__title {
  margin-top: 10px;
}

.card__buy-btn {
  margin-top: 1px;
  margin-left: auto;
}

.card_h {
  overflow: hidden;
  flex-direction: row;
  width: 893px;
  max-width: 100%;

  .card__header {
    min-height: auto;
  }

  .card__img {
    height: 100%;
    overflow: hidden;
    width: 346px;
    flex-shrink: 0;
  }

  .card__body {
    padding: 30px 20px 30px 36px;
    @media (max-width: $mobile) {
      padding: 10px 20px;
    }
  }

  // .card__labels-list {
  //   width: 100%;
  //   position: static;
  //   margin-bottom: 16px;
  //   z-index: 0;
  // }

  .sell-info {
    grid-template-columns: 1fr 120px 1fr;
    gap: 14px;
  }

  .sell-info_single {
    grid-template-columns: 1fr;
  }
}

.card_main {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;

  .card__wrapper {
    min-height: 520px;
  }

  .card__body {
    padding: 33px 120px 36px;
    flex-direction: row;
    align-items: flex-start;

    @media (max-width: $mobile) {
      flex-direction: column;
      padding: 10px 40px;
    }
  }

  .card__labels-list {
    width: 70%;
  }

  .sell-info {
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: $mobile) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .sell-info__col {
    white-space: nowrap;
    padding-right: 50px;

    @media (max-width: $mobile) {
      padding-right: 10px;
    }
  }

  .card__labels-list {
    z-index: 3;
  }

  .sell-info__key {
    @include h7;

    @media (max-width: $mobile) {
      @include base-11;
    }
  }
}

.card__wrapper {
  position: relative;
  overflow: hidden;

  swiper-container {
    border-radius: 20px 20px 0 0;
  }
}

.card__timer-block {
  position: absolute;
  z-index: 2;
  bottom: 20px;
  left: 20px;
  opacity: 0.9;
}

.card__img {
  width: 100%;
  position: relative;
  z-index: 3;
  border-radius: 0;
  max-height: 520px;
  object-fit: cover;

  @media (max-width: $mobile) {
    width: 100%;
    height: 230px;
    object-fit: cover;
    border-radius: 20px 20px 0px 0px;
  }
}

.card__token-card {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  transform: translateX(1000px);
  transition: all 0.4s;
  &_shown {
    transform: translateX(0);
  }
}

.card__btns {
  display: flex;
  margin-left: auto;
  width: fit-content;
  gap: 10px;
}

.card__collapse-btn {
  display: inline-flex;
  width: fit-content;
  text-align: left;
}

.card_collapse-opened {
  .card__img {
    object-fit: cover;
    object-position: left;
  }
}

.card_token {
  width: 941px;
  .purchase-card_h {
    right: 0;
  }
}

.card__operations {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.card__collapse .ReactCollapse--collapse[aria-hidden="false"] {
  margin-top: 20px;
}

.card__header {
  position: relative;
  min-height: 420px;
  background-color: $primary500;
}
