.edit-form {
  margin-top: 50px;
}
.edit-form > .info-form:last-child {
  margin-bottom: 0;
}
.info-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #ebf4ff;
  border-radius: 6px;
  font-size: 16px;
  background-color: #ffffff;
  padding: 24px 24px;
  margin-bottom: 16px;
}
.info-form__block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  & p:first-child {
    color: rgba(144, 144, 144, 1);
  }
  & p:last-child {
    text-align: right;
    word-break: break-word;
  }

  a {
    color: #3399cc;
  }
}
